@import url(https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
html,
body {
	margin: 0;
	font-family: -apple-system, 'Sarabun', BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
}

#app-root {
	position: relative;
	height: 100%;
	width: 100%;
}

#modal-root {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: -webkit-min-content;
	height: min-content;
	width: -webkit-min-content;
	width: min-content;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

